(function( $ ) {

	var datetoday = (new Date()).toISOString().split('T')[0];		 									  
	if ( $( "#checkout-form" ).length ) {
		$( "#downpayment-datepicker, #balance-datepicker" ).datepicker({
			dateFormat: 'yy-mm-dd',
			minDate: 0,
			maxDate: 30
		});
	}

	if ( $( "select.video-language" ).length ) {
		$('select.video-language').on('change', function() {
			var language = $(this).children("option:selected").val();

			$.ajax({
				url: aspectestate_options.ajaxurl,
				data: {
					action: 'video_language_action',
					data: language
				},
				success: function( response ) {
					var $obj = response;

					$( "#video-lang-player" ).html( $obj );
				},
				error: function(XMLHttpRequest, textStatus, errorThrown) {
	        		alert("Status: " + textStatus); alert("Error: " + errorThrown);
	    		}
			});
		});
	}
 
	$("#um_field_420_showpass input[type='checkbox'], #um_field_419_showpassr input[type='checkbox'], #password-section input[type='checkbox']").change(function(){

	  if($(this).is(':checked')){
	   $("#user_password-420, #confirm_user_password-419, #user_password-419, #retype_pass, #new_pass").attr("type","text");
	  }else{
	   $("#user_password-420, #confirm_user_password-419, #user_password-419, #retype_pass, #new_pass").attr("type","password");
	  }

	});

	if ( $( ".prod-gallery-slider" ).length ) {
		// slider slick
	    $(".prod-gallery-slider").slick({
	    	autoplay:true,
		 	slidesToShow: 1,
		 	slidesToScroll: 1,
		 	arrows: true,
		 	fade: false,
		 	asNavFor: '.prod-gallery-thumbnails',
		 	prevArrow:"<button type='button' class='slick-prevs pull-left'><i class='fas fa-angle-left fa-3x'></i></button>",
	        nextArrow:"<button type='button' class='slick-nexts pull-right'><i class='fas fa-angle-right fa-3x'></i></button>"
		});

		$('.prod-gallery-thumbnails').slick({
		 	slidesToShow: 5,
		 	slidesToScroll: 1,
		 	asNavFor: '.prod-gallery-slider',
		 	dots: false,
		 	arrows: false,
		 	focusOnSelect: true
		});

		// lightbox
		$('.prod-gallery-slider').slickLightbox({
		    src: 'src',
			itemSelector: 'div img',
			navigateByKeyboard  : true,
			captionPosition     : 'dynamic',
			caption            : false
		});

	}	

	$("input[name='checkout-account-type']").click(function() {
		var type = $(this).val();

		if(type == 'personal') {
			$('#personal-form').show();
			$('#investor-form').hide();
		} else if(type == 'investor'){
			$('#personal-form').hide();
			$('#investor-form').show();
		}

	});

	// Remove active class from all thumbnail slides
	$('.prod-gallery-thumbnails .slick-slide').removeClass('slick-active');

	// Set active class to first thumbnail slides
	$('.prod-gallery-thumbnails .slick-slide').eq(0).addClass('slick-active');

	// On before slide change match active thumbnail to current slide
	$('.prod-gallery-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
	 	var mySlideNumber = nextSlide;
	 	$('.slider-nav-thumbnails .slick-slide').removeClass('slick-active');
	 	$('.slider-nav-thumbnails .slick-slide').eq(mySlideNumber).addClass('slick-active');
	});

	$(".boxslide, #closed-sidebox a, li.profile a, .mobile a, .top-button a#aspect-search, .top-button a#aspect-download").click(function(){

		var type = $(this).attr('href');

		    $(".side-box").animate({
		      width: "toggle"
		    });

	    $(".top-button a").css("pointer-events", "none");

	    if(type == '#login') {

	    	$('.side-box .box').hide();
	    	$('.login-section').show();

	    } else if(type == '#register') {

	    	$('.side-box .box').hide();
	    	$('.register-section').show();

	     } else if(type == '#search') {

	     	$('.side-box .box').hide();
	    	$('.search-section').show();

	      } else if(type == '#menu') {

	      	$('.side-box .box').hide();
	    	$('.navigation-section').show();

	      } else if (type == '#profile') {

	      	$('.side-box .box').hide();
	      	$('.profile-section').show();

		  } else if (type == '#download') {

		  	$('.side-box .box').hide();
	      	$('.brochure-section').show();

	      } else if (type == '#mobile') {

			$('.side-box .box').hide();
	      	$('.mobile-section').show();

	      }

	    if(type == '#closed') {
	    	$(".top-button a").css("pointer-events", "all");
	    }

	});

    // registration
    if ( $( ".aspectestate-registration" ).length ) {


    	var invest = $(this).children("option:selected").val();

		$.ajax({
			url: aspectestate_options.ajaxurl,
			data: {
				action: 'brokerid_action',
				data: invest
			},
			success: function( response ) {

				var $obj = response;

				if($obj == 0) {
					$("input#aspectus_brokerid-325, input#aspectus_brokerid-419, input#aspectus_brokerids-419").val('');
				} else {
					$("input#aspectus_brokerid-325, input#aspectus_brokerid-419, input#aspectus_brokerids-419").val($obj);
				}

			},
			error: function(XMLHttpRequest, textStatus, errorThrown) {
        		alert("Status: " + textStatus); alert("Error: " + errorThrown);
    		}
		});
	}

	// ajax function
	$('select#invest').on('change', function() {

		var invest = $(this).children("option:selected").val();

		$.ajax({
			url: aspectestate_options.ajaxurl,
			data: {
				action: 'invest_action',
				data: invest
			},
			beforeSend: function() {
				$('#overlay').show();
			},
			success: function( response ) {

				var $obj = response;

				$( ".price-left" ).html( $obj );

				$('#add-to-cart').prop("disabled", false);

				$('#message.error').hide();
				$('#message.success').hide();
				$('#overlay').hide();

			},
			error: function(XMLHttpRequest, textStatus, errorThrown) {
        		alert("Status: " + textStatus); alert("Error: " + errorThrown);
    		}
		});
	});

	$( "#checkout-form a#update-item" ).click(function() {

		var itemId = $('select[name="property_parti[]"]').val();
		var itemIds = [];

		var ccType = $("#cc-payment-type:checked").val();

		var reservationpaymentType = $("#reservation-payment-type:checked").val();
 
		var downpaymentDate = datetoday;
		var downpaymentPayment = $("#downpayment-payment-type:checked").val();

		var balanceDate= $("#balance-datepicker").val();
		var balanceType = $("#balance-payment-type:checked").val();
 
		var finaldownpayment = $('#final-downpayment').val();

		var countryid = $('#countryid').val();

		if(ccType == 'US' && countryid != 'United States' && ccType == 'US' && countryid != 'Canada') {
			alert('Your country must be United States of America or Canada to proceed with the transaction.');

			return false;
		}

		if(ccType == 'Other' && countryid == 'Canada') {
			alert('Your country must not be United States of America or Canada to proceed with the transaction.');

			return false;
		}

		if(ccType == 'Other' && countryid == 'United States') {
			alert('Your country must not be United States of America or Canada to proceed with the transaction.');

			return false;
		}

		if(ccType == undefined) {
			alert('Credit Card Type should not empty!');

			return false;
		}

		if(downpaymentDate.length == 0) {
			alert('Down Payment date should not empty!');

			return false;
		}

		if(balanceDate.length == 0) {
			alert('Balance Payment date should not empty!');

			return false;
		}

		if(reservationpaymentType == undefined) {
			alert('Reservation Payment type should not empty!');

			return false;
		}

		if(balanceType == undefined) {
			alert('Balance Payment type should not empty!');

			return false;
		}

		$('select[name="property_parti[]"]').each(function() {
			itemIds.push($(this).val());
		});

		$('select[name="property_parti[]"]').each(function() {
			itemIds.push($(this).val());
		});

		$.ajax({
			type: 'POST',
			url: aspectestate_options.ajaxurl,
			data: {
				action: 'checkout_action',
				data: { itemIds, downpaymentDate, downpaymentPayment, balanceDate, balanceType, finaldownpayment, reservationpaymentType, ccType}
			},
			success: function( response ) {

				alert('You have successfully updated order portfolio');

				$(location).attr('href', '/checkout#checkout');

			},
			error: function(XMLHttpRequest, textStatus, errorThrown) {
        		alert("Status: " + textStatus); alert("Error: " + errorThrown);
    		}
		});

	});

	$( "#form_orderform" ).submit(function(e) {

		var downpaymentDate = datetoday;
		var downpaymentPayment = $("#downpayment-payment-type:checked").val();
		var balanceDate= $("#balance-datepicker").val();
		var balanceType = $("#balance-payment-type:checked").val();

	});

	$( "#checkout-total a#update-item" ).click(function() {

		var downpayment = $('#final-downpayment').val();
		var downpaymentDate = datetoday;
		var downpaymentType = $("#downpayment-payment-type:checked").val();

		$.ajax({
			type: 'POST',
			url: aspectestate_options.ajaxurl,
			data: {
				action: 'update_total_balance_action',
				data: { downpayment, downpaymentDate, downpaymentType}
			},
			success: function( response ) {

				alert('You have successfully updated order portfolio');

				$(location).attr('href', '/checkout#checkout')

			},
			error: function(XMLHttpRequest, textStatus, errorThrown) {
        		alert("Status: " + textStatus); alert("Error: " + errorThrown);
    		}
		});
	});

	$( "#add-to-cart" ).click(function() {

		var price = $('#price').val();
		var total_order = $('#total_order').val();
		var percent = $('#percentage').val();
		var id = $('#id').val();
		var order_date = $('#date-order').val();
		var PropertyId = $('#PropertyId__code').val();
		var VentureId = $('#VentureId__code').val();
		var CommunityId = $('#Community__code').val();
		var MainOwner = $('#MainOwner__name').val();
		var MainCode = $('#MainOwner__code').val();
		var Code = $('#Code').val();
		var Portfolioid = $('#Portfolioid').val();
		var iSubCommunity__name = $('#iSubCommunity__name').val();
		var iProperty__name = $('#iProperty__name').val();
		var Unitshare = $('#unitshare').val();
		var unit_name = $('#unit_name').val();

		$.ajax({
			type: 'POST',
			url: aspectestate_options.ajaxurl,
			data: {
				action: 'add_to_cart_action',
				data: { price, total_order, percent, id, order_date, PropertyId, VentureId, CommunityId, MainOwner, MainCode, Code, Portfolioid, Unitshare, iSubCommunity__name, iProperty__name, unit_name}
			},
			beforeSend: function() {
				$('#overlay').show();
			},
			success: function( response ) {

				var $cart = response;

				$( ".invest-cart-result" ).html( $cart );

				$( "#clear-id, .invest-cart-result, #checkout-id" ).show();

				$('#add-to-cart').prop("disabled", true);

				$(window).scrollTop(200);

				$('#overlay').hide();

			},
			error: function(XMLHttpRequest, textStatus, errorThrown) {
        		alert("Status: " + textStatus); alert("Error: " + errorThrown);
    		}
		});
	});

	$( ".delete-prod" ).click(function() {

		var ids = $(this).attr('href');
		var id = ids.replace(/[_\W]+/g, "");
		var type = 'checkout';

		$.ajax({
			type: 'POST',
			url: aspectestate_options.ajaxurl,
			data: {
				action: 'clear_item_incart_action',
				data: { id, type }
			},
			beforeSend: function() {
				$('#overlay').show();
			},
			success: function( response ) {

				var $cart = response;

				alert('You have successfully delete the portfolio');

				$(location).attr('href', '/checkout#checkout')

			},
			error: function(XMLHttpRequest, textStatus, errorThrown) {
        		alert("Status: " + textStatus); alert("Error: " + errorThrown);
    		}
		});
	});

	$( "#clear-id" ).click(function() {

		var price = $('#price').val();
		var percent = $('#percentage').val();
		var id = $('#id').val();
		var type = 'product';

		$.ajax({
			type: 'POST',
			url: aspectestate_options.ajaxurl,
			data: {
				action: 'clear_item_incart_product_action',
				data: { price : price, percent : percent, id : id }
			},
			beforeSend: function() {
				$('#overlay').show();
			},
			success: function( response ) {

				var $cart = response;

				$( ".invest-cart-result" ).html( $cart );

				$('select#invest option:first').prop('selected',true);

				$('#add-to-cart').prop("disabled", true);

				$( ".price-left" ).html(" ");

				$(window).scrollTop(200);

				$('#overlay').hide();

			},
			error: function(XMLHttpRequest, textStatus, errorThrown) {
        		alert("Status: " + textStatus); alert("Error: " + errorThrown);
    		}
		});
	});

	$( "#invest_save_account_details" ).click(function() {

		var fname = $('#account_first_name').val();
		var lname = $('#account_last_name').val();
		var retype_pass = $('#retype_pass').val();
		var new_pass = $('#new_pass').val();
		var email = $('#email').val();
		var phone = $('#phone').val();
		var city = $('#city').val();
		var postcode = $('#postcode').val();
		var address = $('#address').val();
		var country = $('#country').val();
		var language = $('#language').val();

		$.ajax({
			type: 'POST',
			url: aspectestate_options.ajaxurl,
			data: {
				action: 'update_profile_user_action',
				data: { fname, lname, retype_pass, new_pass, email, phone, city, postcode, address, country, language}
			},
			beforeSend: function() {
				$('#overlay').show();
			},
			success: function( response ) {

				var $profile = response;

				$( ".profile-invest-result" ).html($profile);

				$('#overlay').hide();

				$(location).attr('href', '/account/profile')

			},
			error: function(XMLHttpRequest, textStatus, errorThrown) {
        		alert("Status: " + textStatus); alert("Error: " + errorThrown);
    		}
		});
	});

	$(".session").click(function() { 
		$('.col-md-4').removeClass( "selected" );				
		$(this).parent().toggleClass('selected', this.checked);

		var radioValue = $(this).attr('data').split(',');

		$('.mc_embed_signup').hide();

		if(radioValue[0]){
			$('.'+radioValue[0]).show();
			$("#mc_embed_signup."+radioValue[0]+" #mce-MMERGE5").val(radioValue[1]);
		}

	});


	$(document).ready(function(){

        $("input[id='inlineRadio']").click(function() {

            var radioValue = $("input[id='inlineRadio']:checked").val().split(',');

            $('.mc_embed_signup').hide();

            if(radioValue[0]){
            	$('.'+radioValue[0]).show();
            	$("#mc_embed_signup."+radioValue[0]+" #mce-MMERGE5").val(radioValue[1]);
            }

            alert('Please fill-up the form below.');

        });		

		$("input[id='cc-payment-type']").click(function() {
			
			var paymenttype = $("input[id='cc-payment-type']:checked").val();

			if(paymenttype == 'US') {

				$('.payment-type #crytpo').hide();
				$('.payment-type #cc').hide();
				$('#final-payment.payment-type #crytpo').show();	 		
				$('li.details-cc').hide(); 			

				jQuery('#wire-transfer input[value="Wire Transfer"]').trigger('click').addClass("working");				

			} else {
				$('li.details-cc').show();  
				$('#final-payment.payment-type #crytpo').show(); 			
				$('.payment-type #cc').show();

				$('#wire-transfer input[value="Wire Transfer"]').attr("checked" , false ); 
			}
	
		});	

    });

	$(document).on("click", '[data-toggle="lightbox"]', function(event) {

		event.preventDefault();

		$(this).ekkoLightbox();
	}); 											
	
	
}( jQuery ));
